<template>
<div>
  <div class="full-width cart">
    <div class="container m-b-5">

      <div class="col-3 mobi-space p-r-20">
        <div class="full-width spacer no-mobi"></div>
      </div>

      <div class="col-6 mobi-space p-r-20">

        <div class="full-width spacer no-mobi"></div>
        <h2 class="shipping-title">HAI DIMENTICATO LA TUA PASSWORD?</h2>

        <p>Inserisci la tua mail per reimpostarla in modo semplice</p>

        <div class="row spaced">
          <div class="col-12">
            <input type="text" placeholder="Email" v-model="recover.email" />
          </div>
        </div>
        <div class="row spaced">
          <div class="col-12 align-center">
            <div class="button" @click="handleRecover()">Recupera password</div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'Account',

  data: function () {
    return {
      recover: {
        email: '',
        recovering: false
      }
    }
  },

  methods: {
    async handleRecover() {
      if(this.recover.recovering) {
        return
      }
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

      if (!re.test(String(this.recover.email).toLowerCase())) {
        this.$toast.open('Il campo email non è valido')
        return false
      }
      this.recover.recovering = true
      try {
        await axios.post(this.$config.backendUrl + 'index.php?action=recover', JSON.stringify(this.recover))

        //await axios.post(this.$config.backendUrl + 'index.php?action=register', JSON.stringify(this.register))
        this.$toast.open('Se esiste un account con questo indirizzo, riceverai una mail per recuperare la password')
      } catch (e) {
        console.log(e)
        this.$toast.open('Qualcosa è andato storto! Riprova più tardi')
      }
    },
  }
}
</script>

<style scoped>
.cart h1 {
  font-weight: 600;
  font-size: 38px;
  margin: 40px 0;
  width: 100%;
}

.cart-table {
  width: 100%;
  border-style:none none solid;
  border-collapse: collapse;
  margin-bottom: 46px;
  border: 0px solid black;
}

.cart .container.column {
  flex-direction: column;
}

thead tr {
  border-bottom: 1px solid #cfcfcf;
  height: 40px;
  line-height: 40px;
}

thead th {
  text-align: left;
  font-family: neue-haas-grotesk-display, sans-serif;
  font-weight: 500;
  letter-spacing: 0.5px;
}

tbody tr {
  height: 120px;
  border-bottom: 1px solid #cfcfcf;
}

.cart-image img {
  width: 90px;
  height: 90px;
  border: 1px solid #eee;
}

.cart-name {
  font-family: neue-haas-grotesk-display, sans-serif;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 0.3px;
}

.cart-discount {
  display: block;
  font-family: neue-haas-grotesk-display, sans-serif;
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 3px;
}

.prod-qty {
  display: inline-block;
  margin-right: 15px;
}

.fa-trash {
  cursor: pointer;
}

.coupon-code {
  background-color: #fff;
  text-align: center;
  height: 32px;
  line-height: 32px;
  width: 100%;
  border: 1px solid #e3e3e3;
  border-radius: 16px;
}

.button {
  height: 32px;
  line-height: 32px;
  font-size: 12px;
}

.coupon-description {
  font-family: neue-haas-grotesk-display, sans-serif;
  font-size: 13px;
  font-weight: 500;
  text-align: center;
  width: 100%;
  letter-spacing: 0.5px;
}

hr {
  border: 1px solid #e3e3e3;
}

.subtotal-row {
  height: 32px;
  line-height: 32px;
  font-family: neue-haas-grotesk-display, sans-serif;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.3px;
}

.totals {
  padding-left: 40px;
}

.interested-in {
  font-weight: 500;
  font-size: 23px;
  letter-spacing: 0.3px;
}

input[type="text"],
input[type="password"]
{
  width: 100%;
  margin-right: 15px;
  height: 32px;
  line-height: 32px;
  padding-left: 10px;
  font-family: neue-haas-grotesk-display, sans-serif;
  font-size: 16px;
  font-weight: 500;
  border: 1px solid #ddd;
  border-radius: 16px;
}

.row.spaced {
  margin-bottom: 20px;
}

.row.spaced .col-6,
.row.spaced .col-12 {
  padding-right: 15px;
  font-family: neue-haas-grotesk-display, sans-serif;
}

.payment-method {
  margin-top: 125px;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.5px;
  font-family: neue-haas-grotesk-display, sans-serif;
  text-transform: uppercase;
}

.fattura {
  margin-bottom: 60px;
}

.shipping-title {
  margin: 0 0 10px 0;
}

.shipping-title + hr {
  margin-bottom: 20px;
}

p {
  line-height: 140%;
  max-width: 90%;
}

p.little-text {
  font-size: 13px;
}

h2 {
  font-size: 28px !important;
}

.button.strange-button {
  border: 3px solid #000;
  background-color: #fff;
  color: #000;
  line-height: 25px;
}

.button.strange-button:hover {
  color: #000 !important;
}

.p-r-20 {
  padding-right: 20px !important;
}

.p-l-20 {
  padding-left: 20px !important;
}

.orders-table {
  width: 50%;
  margin: 0 auto;
  border-collapse: collapse;
}

.orders-table th {
  background-color: #eee;
  padding: 10px 0;
}

.orders-table td {
  height: 30px;
}

@media (max-width: 680px) {

  body .p-r-20 {
    padding-right: 0px !important;
  }

  body .p-l-20 {
    padding-left: 0px !important;
  }

  .shipping-title {
    margin-top: 25px;
    text-align: center;
    margin-bottom: 25px;
  }

  .shipping-title.more-margin {
    margin-top: 50px;
  }

  .row.spaced {
    display: flex;
    justify-content: space-between;
  }

  .row.spaced .col-6,
  .row.spaced .col-12 {
    padding-right: 0;
  }

  .row.spaced .col-6 {
    width: 48%;
  }
}
</style>



